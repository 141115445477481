import axios from "axios";
// import request from "request";
// import store from '../store'
import config from "../config";


function post(url, params={}) {
    // var data = {}
    return new Promise((resolve, reject) => {
        // axios({
        //     method: 'post',
        //     data:{},
        //     url: config.URL+'/user/publicData',
        // }).then(res=>{
        //     store.commit('setheader', )
        //     console.log(res.data.data)
        //     this.$abc = res.data.data
        axios({
            method: 'post',
            data:params,
            url: config.URL+url,
            // headers: {
            //     'Content-Type': 'multipart/form-data',//设置请求头请求格式为JSON
            // },
        }).then(ress=>{
            // data = {
            //     public: res.data.data,
            //     data: ress.data.data
            // }
            resolve(ress.data.data);
        }).catch(err =>{
            reject(err.data)
        })
        // }).catch(function(){
        
        // })
        
        
    });
}

export default {
    post
}