<template>
  <div>
    <div class="top">
      <div class="top_box">
        <el-row>
          <el-col :span="12" class="mgl2">
            <ul class="top_info">
              <li>
                <el-link :underline="false" disabled target="_blank">{{
                  headerData.headerinfo.label1
                }}</el-link>
              </li>
              <li>
                <el-link :underline="false" disabled target="_blank">{{
                  headerData.headerinfo.label2
                }}</el-link>
              </li>
              <!-- <li>
                <el-link :underline="false" target="_blank"
                  >English<i class="el-icon-arrow-down"></i
                ></el-link>
                <ul class="unstyled">
                  <li class="en">
                    <el-link :underline="false" target="_blank"
                      >English</el-link
                    >
                  </li>
                  <li class="zh">
                    <el-link :underline="false" target="_blank">中文</el-link>
                  </li>
                </ul>
              </li> -->
            </ul>
          </el-col>
          <el-col :span="12" class="mgl2">
            <ul class="top_links">
              <li>
                <el-link href="https://j.map.baidu.com/87/HT" target="_blank">
                  <div class="icon_dt"></div>
                </el-link>
              </li>
              <!-- <li>
                <el-link :underline="false" target="_blank">
                  <div class="icon_gzh"></div>
                </el-link>
              </li> -->
              <li>
                <el-link :underline="false" target="_blank">
                  <div class="icon_wx">
                    <div class="wx_code">
                      <div class="wx_code_tip"></div>
                      <div
                        class="wx_code_main"
                        :style="{
                          background:
                            'url(' +
                            config.URL +
                            headerData.headerinfo.wechat_qrcode +
                            ')',
                          backgroundSize: '100%',
                          backgroundPosition: 'center',
                        }"
                      ></div>
                    </div>
                  </div>
                </el-link>
              </li>
              <!-- <li>
                <el-link :underline="false" target="_blank">
                  <div class="icon_qq"></div>
                </el-link>
              </li> -->
            </ul>
          </el-col>
        </el-row>
      </div>
    </div>
    <div id="header" class="header" ref="header">
      <div class="header_box">
        <el-row>
          <el-col :span="10" class="">
            <a :href="config.link">
              <div id="logo_box" class="logo_box">
                <!-- <img src="@/images/logo.png" alt="image" class="logo" /> -->
                <h1 class="title">广州园创技术服务有限公司</h1>
              </div>
            </a>

            <!-- <div class="btn-menu">
              <span></span>
            </div> -->
          </el-col>
          <el-col :span="14" class="">
            <div class="nav-wrap">
              <el-menu class="el-menu-demo" mode="horizontal">
                <el-menu-item index="1" class="el-menu-item" @click="jump"
                  >首页</el-menu-item
                >
                <el-submenu index="2" class="el-submenu" @click="jump">
                  <template #title> 关于我们</template>
                  <el-menu-item
                    :index="'2-' + (index + 1)"
                    v-for="(item, index) in headerData.AboutClass"
                    :id="item.id"
                    :key="index"
                    @click="jump"
                    >{{ item.name }}</el-menu-item
                  >
                </el-submenu>
                <el-submenu index="3" class="el-submenu">
                  <template #title>资讯动态</template>
                  <el-menu-item
                    :index="'3-' + (index + 1)"
                    v-for="(item, index) in headerData.NewsClass"
                    :id="item.id"
                    :key="index"
                    @click="jump"
                    >{{ item.name }}</el-menu-item
                  >
                </el-submenu>
                <el-submenu index="4" class="el-submenu">
                  <template #title>服务展示</template>
                  <el-menu-item
                    :index="'4-' + (index + 1)"
                    v-for="(item, index) in headerData.ServiceClass"
                    :id="item.id"
                    :key="index"
                    @click="jump"
                    >{{ item.name }}</el-menu-item
                  >
                </el-submenu>
                <el-submenu index="5" class="el-submenu">
                  <template #title>成功案例</template>
                  <el-menu-item
                    :index="'5-' + (index + 1)"
                    v-for="(item, index) in headerData.SuccessCaseClass"
                    :id="item.id"
                    :key="index"
                    @click="jump"
                    >{{ item.name }}</el-menu-item
                  >
                </el-submenu>
                <el-submenu index="6" class="el-submenu">
                  <template #title>产品展示</template>
                  <el-menu-item
                    :index="'6-' + (index + 1)"
                    v-for="(item, index) in headerData.ProductClass"
                    :id="item.id"
                    :key="index"
                    @click="jump"
                    >{{ item.name }}</el-menu-item
                  >
                </el-submenu>
                <el-menu-item index="7" class="el-menu-item" @click="jump"
                  >联系我们</el-menu-item
                >
              </el-menu>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
// import { useStore }  from "vuex"
// import { computed, onMounted ,reactive,getCurrentInstance } from "vue"
import config from "../../config";
// import { useRouter } from 'vue-router'
export default {
  props: ['headerData'],
  data () {
    return {
      config: {
        URL: '',
        link: ''
      }
    }
  },
  created () {
    this.config.URL = config.URL
    this.config.link = config.link
  },
  mounted () {
    window.addEventListener('scroll', this.windowScroll)
  },
  methods: {
    windowScroll () {
      // 滚动条距离页面顶部的距离
      // 以下写法原生兼容
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      // console.log(scrollTop);
      if (scrollTop > 130) {
        this.$refs.header.style.position = 'fixed'
        this.$refs.header.style.top = '0'
      } else if (scrollTop < 130) {
        this.$refs.header.style.position = 'unset'
      }
    },
    jump (e) {
      console.log(e.index)
      var index = e.index.split('-')
      var id = e.$attrs.id
      switch (index[0]) {
        case "1":
          this.$router.push({ path: '/' })
          break;
        case "2":
          this.$router.push({ path: '/about', query: { classid: id } })
          break;
        case "3":
          this.$router.push({ path: '/new', query: { classid: id } })
          break;
        case "4":
          this.$router.push({ path: '/service', query: { classid: id } })
          break;
        case "5":
          this.$router.push({ path: '/case', query: { classid: id } })
          break;
        case "6":
          this.$router.push({ path: '/goods', query: { classid: id } })
          break;
        case "7":
          this.$router.push({ path: '/contact' })
          break;

      }
    }
  }


};
</script>

<style lang='scss' scoped>
.top {
  z-index: 2;
  width: 100%;
  background-color: #25232b;
  padding: 6px 0px 3px 0px;
}

.top_box {
  margin: 0 auto;
  max-width: 1200px;
}

.top_info {
  display: flex;
  flex-direction: row;
  padding: 5px;
}

.top_info > li {
  padding: 0px 20px;
  justify-content: flex-start;
  border-left: 1px solid #ccc;
}

.top_info > li:first-child {
  border-left: none;
}

.unstyled {
  position: absolute;
  left: 100px;
  top: 100%;
  width: 100px;
  margin-top: 15px;
}

.top_links {
  display: flex;
  flex-direction: row-reverse;
  padding: 5px;
}

.top_links > li {
  padding: 0px 20px;
  justify-content: flex-end;
}

.header {
  width: 100%;
  height: 80px;
  z-index: 10001;
  background-color: #fff;
  padding: 5px 0;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.1);
}

.header_box {
  margin: 0 auto;
  max-width: 1200px;
  a {
    text-decoration: none;
  }
}

.el-menu-demo {
  display: flex;
  justify-content: flex-end;
  border-bottom: none;
}

.logo_box {
  display: flex;
  flex-direction: row;
  text-align: center;
  color: #000;
}

.logo_box .logo {
  margin-left: 20px;
  width: auto;
  height: 80px;
  justify-content: flex-start;
}

.logo_box .title {
  font-size: 26px;
  margin-left: 20px;
  height: 80px;
  line-height: 80px;
  justify-content: flex-start;
}

.icon_wx {
  width: 20px;
  height: 20px;
  background: url("../../images/wx.png");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  position: relative;
  .wx_code {
    display: none;
    position: absolute;
    z-index: 1000;
    top: 25px;
    left: -65px;
    width: 150px;
    height: 170px;
    .wx_code_tip {
      width: 100%;
      height: 20px;
      background: url("../../images/logo/tip.png");
      background-repeat: no-repeat;
      background-size: 20%;
      background-position: center;
      opacity: 0;
    }
    .wx_code_main {
      width: 150px;
      height: 150px;
      background: #999;
      opacity: 0;
    }
  }
}

.icon_wx:hover {
  .wx_code {
    display: block;
    .wx_code_tip {
      transition: all 1s;
      opacity: 1;
    }
    .wx_code_main {
      transition: all 1s;
      opacity: 1;
    }
  }
}

.icon_qq {
  width: 20px;
  height: 20px;
  background: url("../../images/qq.png");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
}

.icon_gzh {
  width: 20px;
  height: 20px;
  background: url("../../images/gzh.png");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
}

.icon_dt {
  width: 20px;
  height: 20px;
  background: url("../../images/dt.png");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
}
</style>