<template>
  <div v-if="index">
    <div class="home">
      <div class="swiper">
        <div class="swiper_box">
          <div class="swiper_main">
            <Swiper :banner="index.banner" />
          </div>
        </div>
      </div>
      <div class="skip">
        <div class="skip_box">
          <!-- <div class="skip_title">
            <h2>公司介绍</h2>
            <p>作为以供应链为基础的技术与服务企业</p>
          </div> -->
          <el-row class="skip_main">
            <el-col
              :span="8"
              v-for="(item, index) in index.functionDisplay"
              :key="index"
              class="skip_main_box"
            >
              <div
                class="skip_info"
                :data-type="index"
                @click="gotoview($event)"
              >
                <div class="skip_img">
                  <img
                    :src="config.URL + item.image"
                    width="360"
                    height="280"
                  />
                </div>
                <div class="skip_text">
                  广州市天河区广州大道中988号2301房E20房（仅限办公）
                  <h3>{{ item.title }}</h3>
                  <p>{{ item.content }}</p>
                  <span>了解更多</span>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="connection">
        <div class="connection_box">
          <div class="connection_left">
            <p class="connection_left_top">更深入了解</p>
            <p class="connection_left_bottom">我们的服务</p>
          </div>
          <div class="connection_right">
            <el-button
              type="primary"
              round
              class="connection_right_bt"
              data-type="jumpservice"
              @click="jump($event)"
              >更多服务</el-button
            >
          </div>
        </div>
      </div>
      <div class="slogan">
        <div class="slogan_box">
          <div class="slogan_main">
            <div class="slogan_img"></div>
            <div class="slogan_text">
              <h2>{{ index.slogan.title }}</h2>
              <p>{{ index.slogan.content }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="services" id="services">
        <div class="services_box">
          <div class="services_top">
            <div class="services_title">
              <h2>服务展示</h2>
            </div>
          </div>
          <div class="services_bottem">
            <el-row class="services_main">
              <el-col
                :span="6"
                v-for="(item, index) in index.ServiceClass"
                :key="index"
                class="services_col"
                :class="(index + 1) % 4 == 0 ? '' : 'services_col_bg'"
              >
                <div
                  class="services_main_box"
                  data-type="jumpservices"
                  :data-classid="item.id"
                  @click="jump($event)"
                >
                  <div class="services_main_top">
                    <div class="services_main_img_box">
                      <div
                        class="services_main_img"
                        :style="{
                          background: 'url(' + config.URL + item.image + ')',
                          backgroundSize: '60%',
                          backgroundPosition: 'center',
                          backgroundRepeat: 'no-repeat',
                        }"
                      >
                        <!-- <div class="services_main_img" > -->
                      </div>
                    </div>
                  </div>
                  <div class="services_main_bottem">
                    <h3 class="services_main_title">{{ item.name }}</h3>
                    <p>{{ item.introduce }}</p>
                    <!-- <p>服务介绍服务介绍服务介绍服务介绍服务介绍服务介绍服务介绍服务介绍服务介绍服务介绍服务介绍服务介绍</p> -->
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
      <div class="product" id="product">
        <div class="product_box">
          <div class="product_top">
            <div class="product_title">
              <h2>产品展示</h2>
            </div>
          </div>
          <div class="product_bottem">
            <div class="product_nav">
              <el-radio-group
                v-model="radio1"
                class="product_nav_main"
                @change="changeProduct($event)"
              >
                <el-radio-button
                  v-for="(item, index) in index.ProductClass"
                  :key="index"
                  :label="index"
                >
                  {{ item.name }}
                </el-radio-button>
              </el-radio-group>
            </div>
            <div class="product_introduce">
              <h3>{{ productIndex.introduce }}</h3>
            </div>
            <div class="product_main">
              <el-row class="product_main_box">
                <el-col
                  :span="6"
                  v-for="(item, index) in productIndex.index"
                  :key="index"
                  class="product_main_list"
                >
                  <!-- <div class="product_main_img" :style="'background:url(\''+require('../../images/swiper1.png')+'\')'"> -->
                  <div
                    class="product_main_img_box"
                    data-type="jumpproduct"
                    :data-classid="productIndex.id"
                    :data-id="item.id"
                    @click="jump($event)"
                  >
                    <div
                      class="product_main_img"
                      :style="{
                        background: 'url(' + config.URL + item.image + ')',
                        backgroundSize: '100%',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                      }"
                    ></div>
                    <div class="product_main_title">
                      <h4>{{ item.title }}</h4>
                    </div>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
        </div>
      </div>
      <div class="us" id="us">
        <div class="us_box">
          <div class="us_top">
            <div class="us_title">
              <h2>关于我们</h2>
              <p>{{ index.about.introduce }}</p>
            </div>
          </div>
          <div class="us_bottem">
            <div class="us_left">
              <div
                class="us_img"
                :style="{
                  background: 'url(' + config.URL + index.about.image + ')',
                  backgroundSize: '100%',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                }"
              ></div>
            </div>
            <div class="us_right">
              <h3>{{ index.about.title }}</h3>
              <p>{{ index.about.content }}</p>
              <div class="us_bt_box">
                <el-button
                  type="primary"
                  data-type="jumpabout"
                  @click="jump($event)"
                  class="us_bt"
                  >了解更多</el-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="illustration">
        <div class="illustration_box">
          <div class="illustration_title">
            <h2>我们的优势</h2>
          </div>
          <el-row class="illustration_info">
            <el-col
              :span="6"
              class="illustration_col"
              v-for="(item, index) in index.advantage"
              :key="index"
            >
              <div class="illustration_main">
                <div class="illustration_top">
                  <div class="illustration_img_box">
                    <!-- <div class="icon_experience illustration_img">
                    </div> -->
                    <div
                      class="icon_experience illustration_img"
                      :style="{
                        background: 'url(' + config.URL + item.icon + ')',
                        backgroundSize: '100%',
                        backgroundPosition: 'center',
                      }"
                    ></div>
                  </div>
                </div>
                <div class="illustration_bottem">
                  <h3>{{ item.title }}</h3>
                  <p>{{ item.content }}</p>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="brand">
        <div class="brand_box">
          <div class="brand_top">
            <h2>累计合作品牌</h2>
            <p>3000+企业的选择</p>
          </div>
          <div class="brand_bottem">
            <el-row class="brand_row">
              <el-col
                :span="4"
                class="brand_col"
                v-for="(item, index) in index.co_branding_img"
                :key="index"
              >
                <div
                  class="brand_logo"
                  :style="{
                    background: 'url(' + config.URL + item + ')',
                    backgroundSize: '100%',
                    backgroundPosition: 'center',
                  }"
                ></div>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from '@/components/swiper/swiper.vue'
// import { getCurrentInstance, onMounted } from "vue";
import config from "../../config";
export default {
  name: 'Home',
  data () {
    return {
      radio1: '',
      products: {},
      productIndex: {},
      index: '',
      config: {
        URL: ''
      }
    }

  },
  components: {
    Swiper,
  },
  created () {
    this.config.URL = config.URL
    const loading = this.$loading({
      lock: true,
      text: 'Loading',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 1)'
    });

    var ts = this
    this.$util.post('/user/indexData').then(res => {
      console.log(res)
      ts.index = res.index
      ts.radio1 = res.index.ProductClass && res.index.ProductClass[0] && res.index.ProductClass[0].name ? res.index.ProductClass[0].name : ''
      ts.product = res.index.ProductClass
      ts.productIndex = {
        id: res.index.ProductClass && res.index.ProductClass[0] && res.index.ProductClass[0].id ? res.index.ProductClass[0].id : '',
        introduce: res.index.ProductClass && res.index.ProductClass[0] && res.index.ProductClass[0].introduce ? res.index.ProductClass[0].introduce : '',
        index: res.index.ProductClass && res.index.ProductClass[0] && res.index.ProductClass[0].product ? res.index.ProductClass[0].product : ''
      }
      console.log(ts.productIndex)
      loading.close();
    })
  },
  mounted () {

  },
  methods: {
    jump (e) {
      var type = e.currentTarget.dataset.type
      switch (type) {
        case 'jumpservice':
          this.$router.push({ path: '/service' })
          break;
        case 'jumpservices':
          var services_classid = e.currentTarget.dataset.classid
          this.$router.push({ path: '/service', query: { classid: services_classid } })
          break;
        case 'jumpproduct':
          var product_classid = e.currentTarget.dataset.classid
          var product_id = e.currentTarget.dataset.id
          this.$router.push({ path: '/goods', query: { classid: product_classid, id: product_id } })
          break;
        case 'jumpabout':
          this.$router.push({ path: '/about' })
          break;
      }
    },
    gotoview (e) {
      var type = e.currentTarget.dataset.type;
      switch (type) {
        case "0":
          document.getElementById('us').scrollIntoView({ block: 'start', behavior: 'smooth' })
          break;
        case "1":
          document.getElementById('services').scrollIntoView({ block: 'start', behavior: 'smooth' })
          break;
        case "2":
          document.getElementById('product').scrollIntoView({ block: 'start', behavior: 'smooth' })
          break;
      }

    },
    changeProduct (e) {
      var key = e;
      this.productIndex = {
        id: this.product[key].id,
        introduce: this.product[key].introduce,
        index: this.product[key].product
      }
    }
  }
}
</script>

<style scoped lang='scss'>
.home {
  color: #000;
  .swiper {
    min-width: 1200px;
    text-align: center;
    .swiper_box {
      width: 100%;
      position: relative;
      height: 540px;
      margin: auto;
      overflow: hidden;
      .swiper_main {
        position: relative;
        left: 50%;
        width: 1920px;
        height: 540px;
        float: left;
        margin-left: -960px;
      }
    }
  }
  .skip {
    padding: 50px 0;
    width: 100%;
    background: #fff;

    .skip_box {
      width: 1200px;
      margin: 20px auto;
      text-align: center;
      .skip_title {
        padding-bottom: 20px;
        h2 {
          color: #111;
          font-weight: 700;
          font-size: 30px;
        }
        p {
          color: #333;
          margin-top: 10px;
          font-weight: 700;
          font-size: 20px;
        }
      }
    }
  }
  .skip_main {
    padding: 30px 0;
    .skip_main_box {
      .skip_info {
        width: 360px;
        height: 475px;
        margin: 0 20px;
        border-radius: 3%;
        box-shadow: 2px 4px 6px #ccc;
        transition: 0.5s;
        .skip_img {
          img {
            border-top-left-radius: 3%;
            border-top-right-radius: 3%;
          }
        }
        .skip_text {
          padding: 20px;
          text-align: left;
          background: #fff;
          border-bottom-left-radius: 3%;
          border-bottom-right-radius: 3%;
          h3 {
            font-weight: 600;
          }
          p {
            height: 72px;
            font-size: 14px;
            margin-top: 10px;
            text-indent: 30px;
            color: #646868;
            word-break: break-all;
            text-overflow: ellipsis;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          span {
            display: block;
            font-size: 15px;
            text-align: right;
            padding: 10px;
            color: #1688d8;
            border-bottom: 1px solid #1688d8;
          }
        }
      }
      .skip_info:hover {
        transition: 0.5s;
        transform: translateY(-16px);
      }
    }
  }
  .connection {
    background: #ccc;
    .connection_box {
      width: 1200px;
      height: 100px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      padding: 10px 0;
      .connection_left {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 40px;
        .connection_left_top {
          font-size: 15px;
          color: #777;
          margin-bottom: 10px;
        }
        .connection_left_bottom {
          font-size: 25px;
          font-weight: 600;
          color: #222;
        }
      }
      .connection_right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-right: 40px;
      }
    }
  }
  .slogan {
    min-width: 1200px;
    text-align: center;
    height: 300px;
    border-bottom: 10px solid #1688d8;
    background-image: url("../../images/slogan.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    .slogan_box {
      width: 100%;
      position: relative;
      margin: auto;
      .slogan_main {
        .slogan_text {
          color: #fff;
          width: 600px;
          height: 180px;
          margin: 0 auto;
          h2 {
            padding-top: 60px;
            font-size: 30px;
            margin-bottom: 30px;
          }
          p {
            font-size: 20px;
          }
        }
      }
    }
  }
  .services {
    min-width: 1200px;
    text-align: center;
    padding: 100px 0;
    .services_box {
      width: 1200px;
      margin: 0 auto;
      .services_top {
        .services_title {
          h2 {
            color: #262626;
            font-weight: 700;
            font-size: 30px;
            margin: 0 auto;
            width: 200px;
            padding-bottom: 20px;
            margin-bottom: 20px;
            border-bottom: 3px solid #1296db;
          }
        }
      }
      .services_bottem {
        .services_main {
          .services_col {
            .services_main_box {
              background: #fff;
              padding: 50px 0;
              width: 99%;

              .services_main_top {
                padding: 0 30px;
                .services_main_img_box {
                  width: 150px;
                  height: 150px;
                  margin: 0 auto;
                  border-radius: 50%;
                  border: 3px solid #1296db;
                  transition: 0.5s;
                  .services_main_img {
                    width: 90%;
                    height: 90%;
                    margin: 0 auto;
                    position: relative;
                    top: 0;
                    margin-top: 4px;
                    border-radius: 50%;
                    border: 3px solid #1296db;
                    // background: url('../../images/supply.png');
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: 60%;
                  }
                }
              }
              .services_main_bottem {
                padding: 0 30px;
                .services_main_title {
                  margin-top: 20px;
                }
                p {
                  text-align: left;
                  height: 54px;
                  color: #646868;
                  margin-top: 10px;
                  font-size: 14px;
                  text-indent: 28px;
                  word-break: break-all;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                }
              }
            }
            .services_main_box:hover {
              .services_main_img_box {
                transition: 0.5s;
                transform: scale(1.2);
                background: #a0d0eb;
              }
            }
          }
        }
      }
    }
  }
  .product {
    background: #eee;
    text-align: center;
    padding: 100px 0;
    .product_box {
      width: 1200px;
      margin: 0 auto;
      .product_top {
        .product_title {
          h2 {
            color: #262626;
            font-weight: 700;
            font-size: 30px;
            margin: 0 auto;
            width: 200px;
            padding-bottom: 20px;
            margin-bottom: 20px;
            border-bottom: 3px solid #1296db;
          }
        }
      }
      .product_bottem {
        margin-top: 50px;
        // .product_nav{
        //   .product_nav_main{
        //     .product_nav_list{

        //     }
        //   }
        // }
        .product_introduce {
          margin: 0 auto;
          width: 600px;
          margin-top: 40px;
          h3 {
            max-height: 67px;
            word-break: break-all;
            text-overflow: ellipsis;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }
        .product_main {
          padding: 20px 0 0 0;
          .product_main_box {
            .product_main_list {
              .product_main_img_box {
                position: relative;
                background: #000;
                margin: 20px;
                transition: 0.5s;
                .product_main_img {
                  width: 260px;
                  height: 140px;
                  background-size: 100%;
                  background-position: center;
                  opacity: 0.5;
                }
                .product_main_title {
                  position: absolute;
                  color: #fff;
                  width: 260px;
                  height: 140px;
                  top: 0;
                  left: 0;
                  right: 0;
                  bottom: 0;

                  h4 {
                    line-height: 140px;
                  }
                }
              }
              .product_main_img_box:hover {
                transform: scale(1.2);
                transition: 0.5s;
              }
            }
          }
        }
      }
    }
  }
  .us {
    background: #fff;
    text-align: center;
    padding: 100px 0;
    // border-bottom: 10px solid #1688d8;
    .us_box {
      width: 1200px;
      margin: 0 auto;
      .us_top {
        .us_title {
          h2 {
            color: #262626;
            font-weight: 700;
            font-size: 30px;
            margin: 0 auto;
            width: 200px;
            padding-bottom: 20px;
            margin-bottom: 20px;
            border-bottom: 3px solid #1296db;
          }
          p {
            width: 600px;
            margin: 0 auto;
            margin-top: 40px;
            line-height: 26px;
            color: #646868;
          }
        }
      }
      .us_bottem {
        display: flex;
        margin-top: 50px;
        .us_left {
          width: 50%;
          .us_img {
            width: 580px;
            height: 300px;
            background-size: 100%;
            background-position: center;
          }
        }
        .us_right {
          display: flex;
          flex-direction: column;
          width: 50%;
          text-align: left;
          h3 {
            color: #262626;
            font-size: 24px;
            font-weight: 700;
            padding-bottom: 15px;
            margin-bottom: 15px;
            border-bottom: 1px solid #eee;
          }
          p {
            text-indent: 30px;
            font-weight: 400;
            height: 200px;
            line-height: 26px;
            color: #646868;
          }
          .us_bt_box {
            text-align: right;
            // .us_bt{

            // }
          }
        }
      }
    }
  }
  .illustration {
    background: url("../../images/illustration.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    text-align: center;
    padding: 100px 0;
    .illustration_box {
      width: 1200px;
      margin: 0 auto;
      color: #fff;
      .illustration_title {
        h2 {
          font-size: 30px;
          padding-bottom: 100px;
        }
      }
      .illustration_info {
        .illustration_col {
          padding: 0 20px;
          .illustration_main {
            .illustration_top {
              .illustration_img_box {
                .illustration_img {
                  width: 120px;
                  height: 120px;
                  margin: 0 auto;
                  background-size: 100%;
                  background-position: center;
                }
              }
            }
            .illustration_bottem {
              margin-top: 40px;
              h3 {
                font-size: 24px;
              }
              p {
                margin-top: 20px;
              }
            }
          }
        }
      }
    }
  }

  .brand {
    margin: 100px 0;
    .brand_box {
      margin: 0 auto;
      width: 1200px;
      text-align: center;
      .brand_top {
        h2 {
          color: #262626;
          font-weight: 700;
          font-size: 30px;
          margin: 0 auto;
          width: 250px;
          padding-bottom: 20px;
          margin-bottom: 20px;
          border-bottom: 3px solid #1296db;
        }
        p {
          width: 600px;
          margin: 0 auto;
          line-height: 26px;
          color: #646868;
        }
      }
      .brand_bottem {
        width: 100%;
        .brand_row {
          margin-top: 40px;
          .brand_col {
            border: 1px solid #eee;
            padding: 20px;
            .brand_logo {
              width: 160px;
              height: 100px;
              background-size: 100%;
              background-position: center;
              background-repeat: no-repeat;
            }
          }
        }
      }
    }
  }

  .services_col_bg {
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 0%,
      rgba(82, 135, 250, 0.5) 50%,
      rgba(255, 255, 255, 0) 100%
    );
  }
}
</style>

