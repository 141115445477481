/*
 * @Author: Reu
 * @Date: 2021-11-03 09:37:33
 * @LastEditTime: 2021-11-16 12:04:40
 * @LastEditors: Reu
 * @FilePath: \yuanshang--pch5\src\config.js
 * @Description: 
 */
const URL = 'https://yshd.parkzs365.com'
const link = 'https://yshd.parkzs365.com'

export default {
  URL, link
}