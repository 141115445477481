<template>
  <div id="app">
    <Header :header-data="headerData" v-if="flag" />
    <router-view></router-view>
    <Footer :footer-data="footerData" v-if="flag" />
    <Service :service-data="ServiceData" v-if="flag" />
    <Gotop v-if="flag" />
  </div>
</template>

<script>
import Header from '@/components/header/header.vue'
import Footer from '@/components/footer/footer.vue'
import Service from '@/components/service/service.vue'
import Gotop from '@/components/gotop/gotop.vue'
export default {
  name: 'App',
  components: {
    Header,
    Footer,
    Service,
    Gotop
  },
  
  data() {
    return {
      headerData:{

      },
      footerData:{

      },
      ServiceData:{

      },
      flag:false
    }
  },
  created(){
    var ts = this
    this.$util.post('/user/publicData').then(res => {
      console.log(res)
      ts.headerData = {
        headerinfo: res.System.header,
        AboutClass: res.AboutClass,
        NewsClass: res.NewsClass,
        ServiceClass: res.ServiceClass,
        SuccessCaseClass: res.SuccessCaseClass,
        ProductClass: res.ProductClass
      }
      ts.footerData = {
        cpinfo: res.System.company_information,
        ServiceClass: res.ServiceClass,
        newlist: res.News,
        friendlyLinks: res.System.friendlyLinks,
        qr_code: res.System.qr_code
      }
      ts.ServiceData = {
        customer_service: res.System.customer_service,
        customer_service_info: res.System.customer_service_info,
        
      } 
      ts.flag = true
    })
  },
  mounted() {
    
  },
  methods:{
    
  }
}
</script>

<style>
*{
  margin: 0;
  border: 0;
  padding: 0;
}

#app {
  color: #fff;
}

ol,ul{
  list-style: none;
  margin: 0;
  padding: 0;
}

#app /deep/ .el-menu-item{
  font-size: 18px;
  height: 80px;
  line-height: 80px;
  font-weight: 600;
  padding: 0 10px;
}

#app /deep/ .el-submenu__title{
  font-size: 18px;
  font-weight: 600;
  height: 80px;
  line-height: 80px;
  padding: 0 10px;
}

</style>
