import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home/home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/about/about.vue')
  },
  {
    path: '/new',
    name: 'New',
    component: () => import('../views/new/new.vue')
  },
  {
    path: '/service',
    name: 'Service',
    component: () => import('../views/service/service.vue')
  },
  {
    path: '/case',
    name: 'Case',
    component: () => import('../views/case/case.vue')
  },
  {
    path: '/goods',
    name: 'Goods',
    component: () => import('../views/goods/goods.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('../views/contact/contact.vue')
  }
]

const router = new VueRouter({
  routes
})

const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}

export default router
