<template>
    <el-carousel  height="540px" arrow="always" >
        <el-carousel-item  :style="{background: 'url('+config.URL+item.image+')',backgroundSize: '100%',backgroundPosition: 'center'}" v-for="(item,index) in banner" :key="index"  >
        </el-carousel-item>
    </el-carousel>
</template>

<script>
import  config  from "../../config";
export default {
  props: ['banner'],
  data() {
    return {
      swiper: [
        {
          src:'../../images/swiper1.png',
        },
        {
          src:'../../images/swiper1.png',
        }
      ],
      config: {
        URL: ''
      }
    }
  },
  components: {
  },
  create(){
    
  },
  mounted() {
    this.config.URL = config.URL
  },
  methods:{
  }
}
</script>

<style scoped lang='scss'>
.el-carousel__item{
    background-repeat: no-repeat;
  background-position: center;
}
</style>

