<template>
  <div>
    <div class="gotop_box" ref="gotop">
        <div class="gotop" @click="gotop">
            <i class="el-icon-top" />
        </div>
    </div>
  </div>
</template>

<script>
// import { useStore }  from "vuex"
// import { computed, onMounted ,reactive,getCurrentInstance } from "vue"
import config from "../../config";
// import { useRouter } from 'vue-router'
export default {
  props: ['headerData'],
  data() {
    return {
      config: {
        URL: '',
        link: ''
      }
    }
  },
  created(){
    this.config.URL = config.URL
    this.config.link = config.link
  },
  mounted() {
      window.addEventListener('scroll', this.windowScroll)
  },
  methods:{
    windowScroll() {
      // 滚动条距离页面顶部的距离
      // 以下写法原生兼容
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
    //   console.log(scrollTop);
      if(scrollTop > 700){
        this.$refs.gotop.style.right='20px'
      }else if(scrollTop < 700){
        this.$refs.gotop.style.right='-50px'
      }
    },
    gotop(){
        window.scrollTo(0, 0)
    }
  }
  

};
</script>

<style lang='scss' scoped>
.gotop_box{
    position: fixed;
    right: -50px;
    bottom: 100px;
    transition: all 0.5s;
    .gotop{
        width: 50px;
        height: 50px;
        background: #1688d8;
        border-radius: 50%;
        box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.5);
        i{
            text-align: center;
            line-height: 50px;
            font-size: 40px;
            color: #fff;
            width: 48px;
            height: 50px;
        }
    }
}

</style>