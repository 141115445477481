<template>
  <div>
    <div class="components">
        <div class="service">
            <div class="service_box">
                <div class="service_img"></div>
                <p class="service_tip">资讯客服</p>
            </div>
            <div class="service_main">
                <div class="service_title">资讯客服</div>
                <ul>
                    <li v-for="(item,index) in ServiceData.customer_service" :key="index">
                        <div class="service_list">
                          <div class="service_list_logo">
                          </div>
                          <div class="service_list_right">
                            <div class="service_name ">
                              {{item.name}}
                            </div>
                            <div class="service_worktime">
                              {{item.label}}
                            </div>
                            <div class="service_btbox">
                              <a class="service_bt" :href="item.url" target="_blank">
                                联系客服
                              </a>
                            </div>
                            
                          </div>
                        </div>
                    </li>
                  </ul>
                  <ul>
                    <li v-for="(item,indexs) in ServiceData.customer_service_info" :key="indexs">
                        <div class="service_list">
                          <div class="service_list_logo">
                          </div>
                          <div class="service_list_right">
                            <div class="service_name ">
                              {{item}}
                            </div>
                            
                          </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <div class="line">
        </div>
        <div class="message">
            <div class="message_box">
                <div class="message_img"></div>
                <p class="message_tip">留言</p>
                
            </div>
            <div class="message_main">
                <div class="message_title">
                  留言
                </div>
                <ul>
                  <li>
                    <div class="message_list">
                      <div class="message_list_title">
                        联系人：
                      </div>
                      <input
                        placeholder="请输入联系人姓名"
                        v-model="info.name"/>
                    </div>
                  </li>
                  <li>
                    <div class="message_list">
                      <div class="message_list_title">
                        邮箱：
                      </div>
                      <input
                        placeholder="请输入联系人邮箱"
                        v-model="info.e_mail"/>
                    </div>
                  </li>
                  <li>
                    <div class="message_list">
                      <div class="message_list_title">
                        地址：
                      </div>
                      <input
                        placeholder="请输入联系人地址"
                        v-model="info.address"/>
                    </div>
                  </li>
                  <li>
                    <div class="message_list">
                      <div class="message_list_title">
                        电话：
                      </div>
                      <input
                        placeholder="请输入联系人电话"
                        v-model="info.landline_phone"/>
                    </div>
                  </li>
                  <li>
                    <div class="message_list">
                      <div class="message_list_title">
                        手机：
                      </div>
                      <input
                        placeholder="请输入联系人手机"
                        v-model="info.phone"/>
                    </div>
                  </li>
                  <li>
                    <div class="message_list">
                      <div class="message_list_title">
                        邮编：
                      </div>
                      <input
                        placeholder="请输入联系人邮编"
                        v-model="info.zip_code"/>
                    </div>
                  </li>
                  <li>
                    <div class="message_list">
                      <div class="message_list_title">
                        传真
                      </div>
                      <input
                        placeholder="请输入传真"
                        v-model="info.fax"/>
                    </div>
                  </li>
                  <li>
                    <div class="message_list">
                      <div class="message_list_title">
                        留言内容
                      </div>
                      <el-input
                        type="textarea"
                        :rows="2"
                        placeholder="请输入内容"
                        v-model="info.text"></el-input>
                    </div>
                  </li>
                  <li>
                    <div class="message_list">
                      <el-button @click="submit()">提交留言</el-button>
                    </div>
                  </li>
                </ul>
              </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['ServiceData'],
  data() {
    return {
      info:{
        name:'',
        e_mail:'',
        address:'',
        landline_phone:'',
        phone:'',
        zip_code:'',
        fax:'',
        text:'',
      }
    }
  },
  components: {
    
  },
  create(){

  },
  methods:{
    submit(){
      var ts = this;
      console.log(this.info)
      if(this.info.name==""){
        this.$message.error('联系人不能为空');
        return false
      }
      if(this.info.phone==""){
        this.$message.error('手机不能为空');
        return false
      }
      if(this.info.text==""){
        this.$message.error('留言内容不能为空');
        return false
      }
      this.$util.post('/user/messagesAdd',ts.info).then(res => {
        console.log(res)
      })
    }
  }
}
</script>

<style scoped lang='scss'>
    .components{
      width: 45px;
      height: 170px;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      // display: flex;
      // flex-direction: column;
      position: fixed;
      right: -9px;
      top: 200px;
      z-index: 101;
      background: #fff;
      box-shadow:2px 2px 5px #000;
      .service{
        width: 36px;
        padding-right: 9px;
        position: relative;
        padding-bottom: 8px;
        .service_box{
          padding: 0 9px;
          .service_img{
            width: 18px;
            height: 18px;
            margin: 0 auto;
            padding-top: 8px;
            background: url('../../images/logo/qq2.png');
            background-size: 100%;
            background-position: center;
            background-repeat: no-repeat;
          }
          .service_tip{
            color: #1688d8;
            padding-top: 4px;
            text-align: center;
            font-size: 14px;
            font-weight: 500;
            line-height: 14px;
          }
        }
        .service_main{
          display: none;
          position: absolute;
          width: 210px;
          right: 36px;
          top: 20px;
          background:#1688d8;
          border-radius: 10px 0 10px 10px;
          font-size: 12px;
          .service_title{
            width: 100%;
            text-align: center;
            font-size: 12px;
            height: 30px;
            line-height: 30px;
            border-bottom: 1px solid  #fff;
          }
          ul{
            width: 100%;
            li{
              .service_list{
                display: flex;
                padding: 8px 20px;
                border-top: 1px solid #eee;
                .service_list_logo{
                  width: 18px;
                  height: 18px;
                  margin-top: 5px;
                  background: url('../../images/logo/qq1.png');
                  background-size: 100%;
                  background-position: center;
                  background-repeat: no-repeat;
                }
                .service_list_right{
                  width: 100%;
                  margin-left: 5px;
                  .service_name{
                    width: 150px;
                  }
                  .service_worktime{
                    width: 150px;
                  }
                  .service_btbox{
                    width: 150px;
                    display: flex;
                    flex-direction: row-reverse;
                    .service_bt{
                      color: #fff;
                      text-decoration: none;
                      border: 1px solid #fff;
                      border-radius: 5px;
                      padding: 0 5px;
                    }
                  }
                }
                
              }
            }
          }
        }
      }
      .service:hover {
        background: #1688d8;
        .service_img{
          background: url('../../images/logo/qq1.png');
            background-size: 100%;
            background-position: center;
            background-repeat: no-repeat;
            transition:0.5s;
        }
        .service_tip{
          color: #fff;
          transition:0.5s;
        }
        @keyframes service_main_css
        {
          from {
            position: absolute;
            right: 36px;
            top: 20px;
          }
          to {
            position: absolute;
            right: 36px;
            top: 0px;
          }
        }
        .service_main{
          display: block;
          animation: service_main_css 0.5s;
          -moz-animation: service_main_css 0.5s;	/* Firefox */
          -webkit-animation: service_main_css 0.5s;	/* Safari 和 Chrome */
          -o-animation: service_main_css 0.5s;	/* Opera */
          position: absolute;
          right: 36px;
          top: 0px;
        }
      }
      .line{
        width: 20px;
        height: 2px;
        background: #1688d8;
        margin-left: 8px;
      }
      .message{
        width: 36px;
        padding-right: 9px;
        position: relative;
        padding-top: 8px;
        padding-bottom: 12px;
        .message_box{
          padding: 0 9px;
          .message_img{
            width: 18px;
            height: 18px;
            margin: 0 auto;
            background: url('../../images/logo/message2.png');
            background-size: 100%;
            background-position: center;
            background-repeat: no-repeat;
          }
          .message_tip{
            color: #1688d8;
            margin-top: 4px;
            text-align: center;
            font-size: 14px;
            font-weight: 500;
            line-height: 16px;
          }
        }
        .message_main{
          display: none;
          position: absolute;
          width: 300px;
          right: 36px;
          top: 20px;
          background:#1688d8;
          border-radius: 10px 0 10px 10px;
          font-size: 12px;

          .message_title{
            width: 100%;
            text-align: center;
            font-size: 12px;
            height: 30px;
            line-height: 30px;
            border-bottom: 1px solid  #fff;
          }
          ul{
            li{
              .message_list{
                display: flex;
                padding: 8px 20px;
                border-top: 1px solid #eee;
                .message_list_title{
                  width: 60px;
                }
                input{
                  padding:2px;
                  border-radius: 2px;
                }
                .el-textarea{
                  width: auto;
                }
                .el-button{
                  margin: 0 auto;
                }
              }
            }
          }
        }
      }
      .message:hover {
        background: #1688d8;
        .message_img{
          background: url('../../images/logo/message1.png');
            background-size: 100%;
            background-position: center;
            background-repeat: no-repeat;
            transition:0.5s;
        }
        .message_tip{
          color: #fff;
          transition:0.5s;
        }
        @keyframes message_main_css
        {
          from {
            position: absolute;
            right: 36px;
            top: 20px;
          }
          to {
            position: absolute;
            right: 36px;
            top: 0px;
          }
        }
        .message_main{
          display: block;
          animation: message_main_css 0.5s;
          -moz-animation: message_main_css 0.5s;	/* Firefox */
          -webkit-animation: message_main_css 0.5s;	/* Safari 和 Chrome */
          -o-animation: message_main_css 0.5s;	/* Opera */
          position: absolute;
          right: 36px;
          top: 0px;
        }
      }
    }
    .components:hover{
      position: fixed;
      right: 0px;
      top: 200px;
      transition:0.5s;
    }
</style>

