var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.footer)?_c('div',{staticClass:"footer"},[_c('el-row',{staticClass:"footer_box",attrs:{"type":"flex","justify":"center"}},[_c('el-col',{staticClass:"call_us",attrs:{"span":6,"align":"top"}},[_c('h2',[_vm._v("联系我们")]),_c('ul',[_c('li',[_vm._v(" 公司地址："),_c('span',[_vm._v(_vm._s(_vm.footerData.cpinfo.address))])]),_c('li',[_vm._v(" 公司电话："),_c('span',[_vm._v(_vm._s(_vm.footerData.cpinfo.contact_tel))])]),_c('li',[_vm._v(" 公司传真："),_c('span',[_vm._v(_vm._s(_vm.footerData.cpinfo.fax))])]),_c('li',[_vm._v(" 公司公司邮箱："),_c('span',[_vm._v(_vm._s(_vm.footerData.cpinfo.email))])]),_c('li',[_vm._v(" 联系人："),_c('span',[_vm._v(_vm._s(_vm.footerData.cpinfo.contact_name))])]),_c('li',[_vm._v(" 联系人电话："),_c('span',[_vm._v(_vm._s(_vm.footerData.cpinfo.contact_tel))])])])]),_c('el-col',{staticClass:"us_link",attrs:{"span":3}},[_c('h2',[_vm._v("服务展示")]),_c('ul',_vm._l((_vm.footerData.ServiceClass),function(item,index){return _c('li',{key:index,ref:"service",refInFor:true,attrs:{"data-type":"service","data-id":item.id},on:{"click":function($event){return _vm.jump($event)}}},[_vm._v(" "+_vm._s(item.name)+" ")])}),0)]),_c('el-col',{staticClass:"new",attrs:{"span":4}},[_c('h2',[_vm._v("新闻动态")]),_c('ul',_vm._l((_vm.footerData.newlist),function(item,index){return _c('li',{key:index,staticClass:"new_list",attrs:{"data-type":"new","data-classid":item.classId,"data-id":item.id},on:{"click":function($event){return _vm.jump($event)}}},[_c('div',{staticClass:"new_img",style:({
                background: 'url(' + _vm.config.URL + item.image + ')',
                backgroundSize: '100%',
                backgroundPosition: 'center',
              })}),_c('div',{staticClass:"new_title"},[_vm._v(" "+_vm._s(item.title)+" ")])])}),0)]),_c('el-col',{staticClass:"links",attrs:{"span":3}},[_c('h2',[_vm._v("友情链接")]),_c('ul',_vm._l((_vm.footerData.friendlyLinks),function(item,index){return _c('li',{key:index},[_c('a',{attrs:{"href":item.url,"target":"_blank"}},[_vm._v(_vm._s(item.name))])])}),0)]),_c('el-col',{staticClass:"code",attrs:{"span":4}},[_c('div',{staticClass:"code_box"},[_c('div',{staticClass:"code_img",style:({
              background:
                'url(' + _vm.config.URL + _vm.footerData.qr_code.image + ')',
              backgroundSize: '100%',
              backgroundPosition: 'center',
            })}),_c('div',{staticClass:"code_right"},[_c('div',{staticClass:"code_phone"}),_c('div',{staticClass:"code_title"},[_c('p',[_vm._v(_vm._s(_vm.footerData.qr_code.tips))])])])])])],1),_c('div',{staticClass:"share"},[_c('div',{staticClass:"share_box"},[_c('a',{staticClass:"icp",attrs:{"href":"https://beian.miit.gov.cn/","target":"_blank"}},[_c('el-image',{attrs:{"src":require('@/images/beian.png')}}),_c('span',{staticClass:"icp-number"},[_vm._v("粤ICP备2021155231号")])],1)]),_c('div',{staticClass:"share_box"},[_vm._v(" Copyright 2021 广州园创技术服务有限公司 All Rights Reserved. ")])])],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }