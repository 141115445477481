<template>
  <div>
    <div class="footer" v-if="footer">
      <el-row class="footer_box" type="flex" justify="center">
        <el-col :span="6" class="call_us" align="top">
          <h2>联系我们</h2>
          <ul>
            <li>
              公司地址：<span>{{ footerData.cpinfo.address }}</span>
            </li>
            <li>
              公司电话：<span>{{ footerData.cpinfo.contact_tel }}</span>
            </li>
            <li>
              公司传真：<span>{{ footerData.cpinfo.fax }}</span>
            </li>
            <li>
              公司公司邮箱：<span>{{ footerData.cpinfo.email }}</span>
            </li>
            <li>
              联系人：<span>{{ footerData.cpinfo.contact_name }}</span>
            </li>
            <li>
              联系人电话：<span>{{ footerData.cpinfo.contact_tel }}</span>
            </li>
          </ul>
        </el-col>
        <el-col :span="3" class="us_link">
          <h2>服务展示</h2>
          <ul>
            <li
              v-for="(item, index) in footerData.ServiceClass"
              :key="index"
              data-type="service"
              :data-id="item.id"
              @click="jump($event)"
              ref="service"
            >
              {{ item.name }}
            </li>
          </ul>
        </el-col>
        <el-col :span="4" class="new">
          <h2>新闻动态</h2>
          <ul>
            <li
              class="new_list"
              v-for="(item, index) in footerData.newlist"
              :key="index"
              data-type="new"
              :data-classid="item.classId"
              :data-id="item.id"
              @click="jump($event)"
            >
              <div
                class="new_img"
                :style="{
                  background: 'url(' + config.URL + item.image + ')',
                  backgroundSize: '100%',
                  backgroundPosition: 'center',
                }"
              ></div>
              <div class="new_title">
                {{ item.title }}
              </div>
            </li>
          </ul>
        </el-col>
        <el-col :span="3" class="links">
          <h2>友情链接</h2>
          <ul>
            <li v-for="(item, index) in footerData.friendlyLinks" :key="index">
              <a :href="item.url" target="_blank">{{ item.name }}</a>
            </li>
          </ul>
        </el-col>
        <el-col :span="4" class="code">
          <div class="code_box">
            <div
              class="code_img"
              :style="{
                background:
                  'url(' + config.URL + footerData.qr_code.image + ')',
                backgroundSize: '100%',
                backgroundPosition: 'center',
              }"
            ></div>
            <div class="code_right">
              <div class="code_phone"></div>
              <div class="code_title">
                <p>{{ footerData.qr_code.tips }}</p>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
      <div class="share">
        <div class="share_box">
          <a href="https://beian.miit.gov.cn/" target="_blank" class="icp">
            <el-image :src="require('@/images/beian.png')" />
            <span class="icp-number">粤ICP备2021155231号</span>
          </a>
        </div>
        <div class="share_box">
          Copyright 2021 广州园创技术服务有限公司 All Rights Reserved.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import config from "../../config.js";
export default {
  props: ['footerData'],
  data () {
    return {
      footer: {

      },
      config: {
        URL: ''
      }
    }
  },
  created () {
    this.config.URL = config.URL
    console.log(this.footerData)
  },
  mounted () {
  },
  methods: {
    jump (e) {
      var type = e.currentTarget.dataset.type
      switch (type) {
        case "service":
          var service_classid = e.currentTarget.dataset.id
          this.$router.push({ path: '/service', query: { classid: service_classid } })
          break;
        case "new":
          var new_classid = e.currentTarget.dataset.id
          var id = e.currentTarget.dataset.id
          this.$router.push({ path: '/service', query: { classid: new_classid, id: id } })
          break;
      }

    }
  }


};
</script>

<style lang='scss' scoped >
.footer {
  width: 100%;
  background: #333;
  border-top: 5px solid #1688d8;
  .footer_box {
    margin: 0 auto;
    height: 350px;
    max-width: 1200px;
    .call_us,
    .us_link,
    .new,
    .links {
      padding-top: 50px;
      h2 {
        font-size: 20px;
      }
      ul {
        margin-top: 20px;
      }
      li {
        margin: 10px 0;
        font-size: 14px;
        color: #ddd;
      }
    }
    .call_us {
      padding-right: 20px;
      span {
        font-size: 12px;
        color: #999;
      }
    }
    .new {
      ul {
        li {
          display: flex;

          .new_img {
            width: 70px;
            height: 45px;
            background: url("../../images/illustration.jpg");
            background-size: 100%;
            background-repeat: no-repeat;
            background-position: center;
            transition: all 0.5s;
          }
          .new_title {
            margin-left: 10px;
            width: 80px;
            height: 34px;
            word-break: break-all;
            text-overflow: ellipsis;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }
        li:hover {
          .new_img {
            transform: scale(1.5);
          }
        }
      }
    }
    .links {
      a {
        color: #ddd;
        text-decoration: none;
      }
    }
    .code {
      .code_box {
        padding-top: 30px;
        .code_img {
          width: 200px;
          height: 200px;
          transition: all 0.5s;
        }
        .code_img:hover {
          transform: scale(1.5);
          border: 1px solid #333;
        }
        .code_right {
          display: flex;
          justify-content: flex-start;
          flex-direction: row;
          .code_phone {
            width: 100px;
            height: 100px;
            background: url("../../images/phone.png");
            background-size: 100%;
            background-position: center;
          }
          .code_title {
            width: 100px;
            margin-top: 20px;
          }
        }
      }
    }
  }
  .share {
    background: #222;
    padding: 10px 0;
    .share_box {
      margin: 0 auto;
      text-align: center;
      padding: 10px 0;
      display: flex;
      justify-content: center;
      .icp {
        color: #fff;
        display: flex;
        align-items: center;
        .icp-number {
          margin-left: 10px;
        }
      }
    }
  }
}
</style>